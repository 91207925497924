import Hero from "../Hero";
import Navbar from "../Navbar";
import AboutImg from "../../assets/banner.jpg";
import Footer from "../Footer";
import { useState, useEffect } from "react";

function Kartal() {
  //dark & light mode integration
  const [mode, setMode] = useState();

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme);
        setMode(colorScheme);
      });
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Biz Kimiz?"
        text="Sadık Bekci"
        btnClass="hide"
        showSlider="false"
      />
      <h1>Servisler</h1>
      <ul>
        <li>
          <p>&#10004;Kartal su tesisatçısı</p>
          <p>&#10004;Kartal kırmadan su kaçak tespiti</p>
          <p>&#10004;Kartal petek temizleme hizmetleri</p>
          <p>&#10004;Kartal yangın tesisatçısı</p>
          <p>&#10004;Kartal İklimlendirme ve Havalandırma Hizmetleri</p>
          <p>&#10004;Kartal Kazan ve Boyler Tesisatı</p>
          <p>&#10004;Kartal sanayi tesisatı hizmetleri</p>
          <p>&#10004;Kartal pimaş tıkanıklığı açma</p>
          <p>&#10004;Kartal lavabo açma</p>
          <p>&#10004;Kartal su tesisatçısı</p>
          <p>&#10004;Kartal Gömme Rezervuar Montajı ve tamiri</p>
        </li>
      </ul>
      <Footer />
    </>
  );
}

export default Kartal;
