import "./FooterStyles.css";

const Footer = (props) => {
  return (
    <div className="footer">
      {props.isEng ? (
        <>
          <div className="top">
            <div>
              <h1>Installation Service</h1>
              <p>Your Technical Support Service</p>
              <p>Sadık Bekci</p>
            </div>
            <div>
              <a href="tel:0534 416 9734">
                <i className="fa-solid fa-phone-square"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/TesisatServisi?mibextid=ZbWKwL"
              >
                <i className="fa-brands fa-facebook-square "></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/tesisatteknikservisi?igshid=MzRlODBiNWFlZA=="
              >
                <i className="fa-brands fa-instagram-square "></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/sadik-bek%C3%A7i-40249159?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <i className="fa-brands fa-linkedin "></i>
              </a>
              <a
                href="https://x.com/tesisatteknik?t=xB30h0ltmkxWLG9sikDQuA&s=08 "
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter-square "></i>
              </a>
              <a href="mailto:tesisatteknikservisi@gmail.com?subject=Merhaba!">
                <i className="fa-solid fa-envelope-square "></i>
              </a>
            </div>
          </div>

          <div className="bottom">
            <div>
              <h4>Project</h4>
              <a href="/eng/extras/changelog">Changelog</a>
              <a href="/eng/extras/changelog">Versions</a>
            </div>
            <div>
              <h4>Community</h4>
              <a
                href="https://github.com/JspBack"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="https://github.com/JspBack"
                target="_blank"
                rel="noreferrer"
              >
                Project
              </a>
            </div>
            <div>
              <h4>Support</h4>
              <a href="/eng/contact">Report error</a>
              <a href="/eng/contact">Contact with us</a>
            </div>
            <div>
              <h4>Extras</h4>
              <a href="">Photo Gallery</a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="top">
            <div>
              <h1>Tesisat Servisi</h1>
              <p>Teknik Destek Ve Servisiniz</p>
              <p>Sadık Bekci</p>
            </div>
            <div>
              <a href="tel:0534 416 9734">
                <i className="fa-solid fa-phone-square"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/TesisatServisi?mibextid=ZbWKwL"
              >
                <i className="fa-brands fa-facebook-square "></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/tesisatteknikservisi?igshid=MzRlODBiNWFlZA=="
              >
                <i className="fa-brands fa-instagram-square "></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/sadik-bek%C3%A7i-40249159?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <i className="fa-brands fa-linkedin "></i>
              </a>
              <a
                href="https://x.com/tesisatteknik?t=xB30h0ltmkxWLG9sikDQuA&s=08 "
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter-square "></i>
              </a>
              <a href="mailto:tesisatteknikservisi@gmail.com?subject=Merhaba!">
                <i className="fa-solid fa-envelope-square "></i>
              </a>
            </div>
          </div>

          <div className="bottom">
            <div>
              <h4>Proje</h4>
              <a href="/extras/changelog">Changelog</a>
              <a href="/extras/changelog">Versiyonlar</a>
            </div>
            <div>
              <h4>Komunite</h4>
              <a
                href="https://github.com/JspBack"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="https://github.com/JspBack"
                target="_blank"
                rel="noreferrer"
              >
                Proje
              </a>
            </div>
            <div>
              <h4>Destek</h4>
              <a href="/contact">Hata Bildirme </a>
              <a href="/contact">Bize Ulaş</a>
            </div>
            <div>
              <h4>Diğerleri</h4>
              <a href="">Foto Galeri</a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
