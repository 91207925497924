import Navbar from "../Navbar";
import Hero from "../Hero";
import "./ChangeLog.css";
import background from "../../assets/black-bg.jpg";

function Changelog() {
  return (
    <div>
      <Navbar />
      <Hero
        heroImg={background}
        title="Changelog"
        cName="hero-mid"
        btnClass="hide"
        showSlider="false"
      />
      <div>V1 -&gt; Deployed(09.29.23) </div>
      <div>V1.1 -&gt; DNS problemleri çözüldü(10.29.23) </div>
      <div>V1.2 -&gt; Yeni servis alanları ve buglar çözüldü(12.19.23) </div>
      <a className="log-button" href="/">
        Geri Dön
      </a>
    </div>
  );
}

export default Changelog;
