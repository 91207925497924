import { Link } from "react-router-dom";
import "./Adress.css";

function Adress(props) {
  return (
    <div className="adress-wrapper">
      {props.isEng ? (
        <>
          <div className="adress-up">
            <h1>Adress Information</h1>
          </div>
          <div className="adress-down">
            <div className="adress-left">
              <div className="phone-div">
                <h2 style={{ textDecorationLine: "underline" }}>Phone no</h2>
                <a className="call-i" href="tel:0534 416 97 34">
                  <p>0534 416 97 34</p>
                </a>
                <a className="call-i" href="tel:0537 499 47 46">
                  <p>0537 499 47 46</p>
                </a>
                <h2 style={{ textDecorationLine: "underline" }}>Landline no</h2>
                <a className="call-i" href="tel:0216 312 0 111">
                  <p>0 216 312 0 111</p>
                </a>
              </div>
              <div className="adress-div">
                <h2 style={{ textDecorationLine: "underline" }}>Main branch</h2>
                <p>Cumhuriyet Mah. Şenlik Cad. 6 Çekmeköy/ISTANBUL</p>

                <h4 className="adress-div">
                  For more service areas <br />{" "}
                  <Link
                    className="call-i"
                    style={{ textDecorationLine: "underline" }}
                    to="/eng/about"
                  >
                    <h4>press</h4>
                  </Link>
                </h4>
              </div>
            </div>
            <div className="adress-right">
              <iframe
                title="Gmap"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d376.2569550716294!2d29.229387953738136!3d41.024038627985334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cace1da433bd37%3A0x12558aa30da5144b!2sdekorasyonservisi!5e0!3m2!1str!2str!4v1695300960157!5m2!1str!2str"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="adress-up">
            <h1>Adres Bilgileri</h1>
          </div>
          <div className="adress-down">
            <div className="adress-left">
              <div className="phone-div">
                <h2 style={{ textDecorationLine: "underline" }}>Cep No</h2>
                <a className="call-i" href="tel:0534 416 97 34">
                  <p>0 534 416 97 34</p>
                </a>
                <a className="call-i" href="tel:0537 499 47 46">
                  <p>0 537 499 47 46</p>
                </a>
                <h2 style={{ textDecorationLine: "underline" }}>Sabit No</h2>
                <a className="call-i" href="tel:0216 312 0 111">
                  <p>0 216 312 0 111</p>
                </a>
              </div>
              <div className="adress-div">
                <h2 style={{ textDecorationLine: "underline" }}>Ana Şubemiz</h2>
                <p>Cumhuriyet Mah. Şenlik Cad. 6 Çekmeköy/ISTANBUL</p>

                <h4 className="adress-div">
                  Tüm hizmet Bölgelerimiz için <br />
                  <Link
                    className="call-i"
                    style={{ textDecorationLine: "underline" }}
                    to="/about"
                  >
                    <h4>tıklayınız</h4>
                  </Link>
                </h4>
              </div>
            </div>
            <div className="adress-right">
              <iframe
                title="Gmap"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d376.2569550716294!2d29.229387953738136!3d41.024038627985334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cace1da433bd37%3A0x12558aa30da5144b!2sdekorasyonservisi!5e0!3m2!1str!2str!4v1695300960157!5m2!1str!2str"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Adress;
