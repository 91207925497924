import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/service1.jpg";
import Footer from "../components/Footer";
import Comp from "../components/Comp";

function Service() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Hizmetler"
        text="%100 kaliteli hizmet"
        btnClass="hide"
        showSlider="false"
      />
      <Comp isDetailed="true" />
      <Footer />
    </>
  );
}

export default Service;
