import "./serviceArea.css";

const serviceArea = () => {
  return (
    <div className="service-wrapper">
      <ul className="service-list">
        <li className="service-left">
          <a className="service-links" href="/pages/atasehir">
            Ataşehir
          </a>
          <a className="service-links" href="/pages/maltepe">
            Maltepe
          </a>
          <a className="service-links" href="/pages/cekmekoy">
            Çekmeköy
          </a>
          <a className="service-links" href="/pages/bostanci">
            Bostancı
          </a>
          <a className="service-links" href="/pages/suadiye">
            Suadiye
          </a>
          <a className="service-links" href="/pages/kadikoy">
            Kadıköy
          </a>
          <a className="service-links" href="/pages/acarkent">
            Acarkent
          </a>
        </li>

        <li className="service-left">
          <a className="service-links" href="/pages/fikirtepe">
            Fikirtepe
          </a>
          <a className="service-links" href="/pages/kalamis">
            Kalamış
          </a>
          <a className="service-links" href="/pages/yenisahra">
            Yenisahra
          </a>
          <a className="service-links" href="/pages/camlica">
            Çamlıca
          </a>
          <a className="service-links" href="/pages/sultanbeyligi">
            Sultanbeyliği
          </a>
          <a className="service-links" href="/pages/beykoz">
            Beykoz
          </a>
          <a className="service-links" href="/pages/osmanaga">
            Osmanağa
          </a>
        </li>

        <li className="service-left">
          <a className="service-links" href="/pages/moda">
            Moda
          </a>
          <a className="service-links" href="/pages/yakacik">
            Yakacık
          </a>
          <a className="service-links" href="/pages/aydos">
            Aydos
          </a>
          <a className="service-links" href="/pages/dragos">
            Dragos
          </a>
          <a className="service-links" href="/pages/kosuyolu">
            Koşuyolu
          </a>
          <a className="service-links" href="/pages/goztepe">
            Göztepe
          </a>
          <a className="service-links" href="/pages/soyak">
            Soyak
          </a>
        </li>
      </ul>

      <ul className="service-list">
        <li className="service-left">
          <a className="service-links" href="/pages/kuzguncuk">
            Kuzguncuk
          </a>
          <a className="service-links" href="/pages/beylerbeyi">
            Beylerbeyi
          </a>
          <a className="service-links" href="/pages/altunizade">
            Altunizade
          </a>
          <a className="service-links" href="/pages/tuzla">
            Tuzla Org.
          </a>
          <a className="service-links" href="/pages/umraniye">
            Ümraniye
          </a>
          <a className="service-links" href="/pages/uskudar">
            Üsküdar
          </a>
          <a className="service-links" href="/pages/sancaktepe">
            Sancaktepe
          </a>
        </li>

        <li className="service-left">
          <a className="service-links" href="/pages/beykoz">
            Kartal
          </a>
          <a className="service-links" href="/pages/kavacik">
            Kavacık
          </a>
          <a className="service-links" href="/pages/acibadem">
            Acıbadem
          </a>
          <a className="service-links" href="/pages/caddebostan">
            Caddebostan
          </a>
          <a className="service-links" href="/pages/feneryolu">
            Feneryolu
          </a>
          <a className="service-links" href="/pages/senesenevler">
            Şenesenevler
          </a>
          <a className="service-links" href="/pages/kozyatagi">
            Kozyatağı
          </a>
        </li>
        <li className="service-left">
          <a className="service-links" href="/pages/tasdelen">
            Taşdelen
          </a>
          <a className="service-links" href="/pages/pendik">
            Pendik
          </a>
          <a className="service-links" href="/pages/imes">
            Imes
          </a>
        </li>
      </ul>
    </div>
  );
};

export default serviceArea;
