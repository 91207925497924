import Hero from "../Hero";
import Navbar from "../Navbar";
import contact from "../../assets/contact.jpg";
import Footer from "../Footer";
import ContactForm from "../ContactForm";
import Adress from "../Adress";

function Engcontact() {
  return (
    <>
      <Navbar isEng="true" />
      <Hero
        cName="hero-mid"
        heroImg={contact}
        title="Contact Us"
        text="Send a message"
        btnClass="hide"
        showSlider="false"
      />
      <ContactForm isEng="true" />
      <Adress isEng="true" />
      <Footer isEng="true" />
    </>
  );
}

export default Engcontact;
