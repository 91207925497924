import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import History from "../components/History";
import { useState, useEffect } from "react";
import Background from "../assets/background.jpg";
import Comp from "../components/Comp";
import "../components/h_button.css";

function Home() {
  //dark & light mode integration
  const [mode, setMode] = useState();

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme);
        setMode(colorScheme);
      });
  }, []);

  return (
    <>
      <Navbar />
      <Hero
        cName={mode === "dark" ? "hero" : "hero-light"}
        heroImg={Background}
        title="Tesisat Mekanik Servisiniz"
        text="
        Ticaretimizin ana amacı ve hedefimiz kesinlikle müşteri memnuniyeti olup, kaliteli ve hızlı teknik destek sağlamaktır."
        url="/contact"
        btnClass={mode === "dark" ? "show" : "show-light"}
        buttonText="Bize Ulaşın"
        showSlider="true"
      />
      <History />
      <h1>Müşteri Memnuniyeti</h1>
      <p>Teknik Hizmet</p>
      <Comp isdetailed="false" />
      <div className="comp-button-div">
        {" "}
        <a href="/service">
          <button className="comp-button">Daha fazlası ⇨</button>
        </a>
      </div>
      <Footer />
    </>
  );
}

export default Home;
