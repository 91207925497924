import Hero from "../Hero";
import Navbar from "../Navbar";
import AboutImg from "../../assets/banner.jpg";
import Footer from "../Footer";
import AboutUs from "../AboutUs";
import { useState, useEffect } from "react";

function Engabout() {
  //dark & light mode integration
  const [mode, setMode] = useState();

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme);
        setMode(colorScheme);
      });
  }, []);
  return (
    <>
      <Navbar isEng="true" />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="About Us"
        text="Sadık Bekci"
        btnClass="hide"
        showSlider="false"
      />
      <AboutUs isEng="true" />
      <Footer isEng="true" />
    </>
  );
}

export default Engabout;
