import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB99F1PdG__b_SsrPa0zNqG3Ma4gnuoEWk",
  authDomain: "tesisat-web.firebaseapp.com",
  projectId: "tesisat-web",
  storageBucket: "tesisat-web.appspot.com",
  messagingSenderId: "339906067728",
  appId: "1:339906067728:web:4c45bda1e5392778717995",
  measurementId: "G-CB91X8QYC5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db, app };
