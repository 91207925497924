import { Component } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import Logodark from "../assets/dark-logo.png";
import LogoLight from "../assets/light-logo.png";

class Navbar extends Component {
  /*Its's so sad that I used chatgpt here :( (but its working)*/
  constructor(props) {
    super(props);
    this.state = {
      isDarkMode: false, // Initialize the state to default light mode
    };
  }

  componentDidMount() {
    // Check for user's preferred color scheme when the component mounts
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    this.setState({ isDarkMode: prefersDarkMode });

    // Listen for changes to the color scheme
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.handleColorSchemeChange);
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", this.handleColorSchemeChange);
  }

  handleColorSchemeChange = (event) => {
    this.setState({ isDarkMode: event.matches });
  };

  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    const { isDarkMode } = this.state;
    const isEng = this.props.isEng;

    return (
      <nav
        className={`navbar ${isDarkMode ? "NavbarItems" : "NavbarItems-light"}`}
      >
        <div className="nav-logo-div">
          <img src={isDarkMode ? LogoLight : Logodark} alt="Logo" />
          <h1
            className={`navbar ${
              isDarkMode ? "navbar-logo" : "navbar-logo-light"
            }`}
          >
            Tesisat Servisi
          </h1>
        </div>

        <div
          className={`navbar ${isDarkMode ? "menu-icons" : "menu-icons-light"}`}
          onClick={this.handleClick}
        >
          <i
            className={`navbar ${
              isDarkMode
                ? this.state.clicked
                  ? "fas fa-times"
                  : "fas fa-bars"
                : this.state.clicked
                ? "fas fa-times"
                : "fas fa-bars"
            }`}
          ></i>
        </div>

        <ul
          className={`navbar ${
            isDarkMode
              ? this.state.clicked
                ? "nav-menu active"
                : "nav-menu"
              : this.state.clicked
              ? "nav-menu-light active"
              : "nav-menu-light"
          }`}
        >
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                {isEng ? (
                  <Link
                    className={
                      isDarkMode ? `${item.cName}` : `${item.cName}` + "-light"
                    }
                    to={item.engUrl}
                  >
                    <i className={item.icon}></i>
                    {item.Eng}
                  </Link>
                ) : (
                  <Link
                    className={
                      isDarkMode ? `${item.cName}` : `${item.cName}` + "-light"
                    }
                    to={item.url}
                  >
                    <i className={item.icon}></i>
                    {item.title}
                  </Link>
                )}
              </li>
            );
          })}
          <Link
            className={
              isDarkMode ? "nav-links-mobile" : "nav-links-mobile-light"
            }
            to={isEng ? "/home" : "/eng/home"}
          >
            {isEng ? "Türkçe" : "English"}
          </Link>

          {isEng ? (
            <Link to="/home">
              <button className="nav-button">Türkçe</button>
            </Link>
          ) : (
            <Link to="/eng/home">
              <button className="nav-button">English</button>
            </Link>
          )}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
