import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/banner.jpg";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import { useState, useEffect } from "react";

function About() {
  //dark & light mode integration
  const [mode, setMode] = useState();

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme);
        setMode(colorScheme);
      });
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Biz Kimiz?"
        text="Sadık Bekci"
        btnClass="hide"
        showSlider="false"
      />
      <AboutUs />
      <Footer />
    </>
  );
}

export default About;
