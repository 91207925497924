import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import contact from "../assets/contact.jpg";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import Adress from "../components/Adress";

function Contact() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={contact}
        title="İletişim"
        text="Bizimle iletişime geç"
        btnClass="hide"
        showSlider="false"
      />
      <ContactForm />
      <Adress />
      <Footer />
    </>
  );
}

export default Contact;
