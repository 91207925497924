import "./Comp.css";
import CompData from "./CompData";
import Tool from "../assets/tools.jpg";
import Team from "../assets/team.jpg";
import Customer from "../assets/customer.jpg";
import Yangin from "../assets/yangin.jpg";
import Dogalgaz from "../assets/dogalgaz.jpg";
import Iklim from "../assets/iklim.jpg";
import Su from "../assets/sutesisat.jpg";
import Sanayi from "../assets/sanayi.jpg";
import Kazan from "../assets/kazan.jpg";
import Petek from "../assets/petek.jpg";
import Kacak from "../assets/kacak.jpg";
import Pimas from "../assets/pimas.jpg";
import Lavabo from "../assets/lavabo.jpg";
import Mutfak from "../assets/mutfak.jpg";
import Gomme from "../assets/gomme.jpg";

function Comp(props) {
  return (
    <div className="comp">
      {props.isDetailed ? (
        props.isEng ? (
          <div className="comp-wrapper">
            {/* IF NEEDED */}
            {/* <h1>Müşteri Memnuniyeti</h1>
              <p>Teknik Hizmet</p> */}
            <div className="compcard">
              <CompData
                // url="/home"
                heading="Fire Installation"
                text="Fire installation, as the name suggests, is generally a system used to extinguish fires that occur anywhere. In general, these installations are suitable for use in many different structures. These installations, which are of great importance in both living and working areas, provide the opportunity to intervene in fires before they turn into a disaster."
                image={Yangin}
              />
              <CompData
                // url="/home"
                heading="Gas Installation"
                text="The location of the installation is very important in terms of accessibility of the pipes used in the natural gas installation, emergency intervention in undesirable situations and periodic checks."
                image={Dogalgaz}
              />
              <CompData
                // url="/home"
                heading="Air Conditioning and Ventilation"
                text="Central Ventilation and Mechanical Systems, Ventilation, Fan, Chimney, Hood Systems, Air Handling Units, Heating and Cooling Systems use the best quality products and offer them to your use in accordance with your needs. Our determination to work, known in the sector, is the most important factor in providing the best service. It is your first address for Plumbing Technical Service jobs."
                image={Iklim}
              />
            </div>
            <div className="compcard">
              <CompData
                // url="/home"
                heading="Water pipe Installation"
                text="Clean water installation; It is a pipe network that carries domestic water to the usage areas in homes and workplaces. More accurately, it is a system consisting of pipes and fittings.
                The part between the city network and the meter in the building is called the external clean water installation, and the columns that distribute the clean water to the floors and the connection pipes that take the water in the columns to the usage areas are called the internal clean water installation."
                image={Su}
              />
              <CompData
                // url="/home"
                heading="Industrial Installation"
                text="It is widely preferred because it is easy to use, does not leave any residue or ash behind, is environmentally friendly and cheap.
                In order to use natural gas in industrial areas, there must be appropriate installation. The route to be followed to transport the gas coming from the source to the usage areas must comply with the technical specifications and relevant regulations. In addition, the mechanical installation required for this work must be suitable for environmental conditions and not affect other systems."
                image={Sanayi}
              />
              <CompData
                // url="/home"
                heading="Boiler Installation"
                text="Boiler is a device used to prepare domestic hot water. Today, hot water required for use in buildings is obtained by various methods. Generally, small and detached buildings use combi boilers, water heaters and water heaters. These are central hot water preparation systems that will provide more economic benefits instead of individual hot water production in places where people live and operate collectively."
                image={Kazan}
              />
            </div>

            <div className="compcard">
              <CompData
                // url="/home"
                heading="Radiator Cleaning"
                text="Radiator cleaning; It is a work covering all pipes and honeycombs of the heating system, carried out by companies specialized in this field. Radiator cleaning is done carefully using some special honeycomb cleaning chemicals and preservatives."
                image={Petek}
              />
              <CompData
                // url="/home"
                heading="Water Leak Detection"
                text="First of all, they check the pressure of the water with a manometer. If there is a decrease in the manometer value, the water leakage is started to be listened to with an acoustic listening device. If the water leak is leaking from a small crack and the sound cannot be heard, the pressure of the clean water installation is increased and listened again with an acoustic listening device. At the end of this process, the water leakage in the house is precisely detected. The area where there is water leakage is immediately opened. It is repaired and closed again. After the repair process is completed, it is checked again for any water leaks."
                image={Kacak}
              />
              <CompData
                // url="/home"
                heading="Pipe Installation"
                text="Trying to open clogged pipes using a pump or wire is one of the most frequently used methods. If there is no major problem, the blockage can be removed with devices such as pumps or wires. It is assumed that the reaction of the two substances used will melt the substance causing the blockage and thus it can be opened."
                image={Pimas}
              />
            </div>
            <div className="compcard">
              <CompData
                // url="/home"
                heading="Sink Opening"
                text="The pump that comes to our aid in such situations generally provides temporary solutions. Because even if it can clear the blockage in the sink, it does not allow it to be completely cleaned.
                Sink openers are another method that comes to mind when unclogging a sink. Drain openers, which are extremely effective in opening sinks, can cause various discomforts if inhaled. We recommend that such situations be left to professional teams."
                image={Lavabo}
              />
              <CompData
                // url="/home"
                heading="Kitchen Bathroom Plumbing"
                text="The work to be done is planned before starting the installation. The places where the pipes will pass are marked with chalk. Before starting to break, it is checked whether there is any installation that could be damaged. Afterwards, operations requiring installation in the specified area are carried out. Afterwards, final checks are made and delivered."
                image={Mutfak}
              />
              <CompData
                // url="/home"
                heading="Concealed Cistern Installation"
                text="The experienced expert you are looking for for cistern repair, disassembly, installation, internal parts repair and new cistern installation is at your door with a phone call. Concealed cistern installation is a process that requires mastery. Problems such as water leakage may occur due to incorrect installation. For this reason, you should choose the master who will install the cistern very carefully."
                image={Gomme}
              />
            </div>
          </div>
        ) : (
          <div className="comp-wrapper">
            {/* IF NEEDED */}
            {/* <h1>Müşteri Memnuniyeti</h1>
                <p>Teknik Hizmet</p> */}
            <div className="compcard">
              <CompData
                // url="/home"
                heading="Yangın Tesisatı"
                text="Yangın tesisatı genel olarak adından da anlaşılacağı üzere herhangi bir yerde ortaya çıkan yangınları söndürmeye yarayan sistemdir. Genel olarak birçok farklı yapıda bu tesisatların kullanımı uygundur. Hem yaşam hem de çalışma alanlarında büyük öneme sahip olan bu tesisatlar ile yangınlar felakete dönüşmeden müdahale imkânı ortaya çıkar."
                image={Yangin}
              />
              <CompData
                // url="/home"
                heading="Doğalgaz Tesisatı"
                text="Doğalgaz tesisatında kullanılan boruların erişilebilir olması istenmeyen durumlarda acil müdahale edilmesi ve dönemsel kontrollerin yapılması bakımından tesisatın konumu çok önemlidir."
                image={Dogalgaz}
              />
              <CompData
                // url="/home"
                heading="İklimlendirme ve Havalandırma"
                text="Merkez Havalandırma ve Mekanik Sistemleri, Havalandırma, Fan, Baca, Davlumbaz Sistemleri, Klima Santralleri, Isıtma ve Soğutma Sistemleri en iyi kalitede ürünler kullanıp ihtiyaçlarınıza uygun olarak kullanımınıza sunmaktadır. Sektörde bilinen çalışma azmimiz en iyi şekilde hizmet vermemizdeki en önemli faktördür. Tesisat Teknik Servisi işlerinde ilk adresinizdir."
                image={Iklim}
              />
            </div>
            <div className="compcard">
              <CompData
                // url="/home"
                heading="Su Tesisatı"
                text="Temiz su tesisatı; evlerde iş yerlerinde kullanım suyunu, kullanma yerlerine kadar ileten boru ağıdır. Daha doğru tabirle borulardan ve ekleme parçalarından oluşan sistemdir.
                    Şehir şebekesi ile binadaki sayaç arasında kalan kısım dış temiz su tesisatı, temiz suyu katlara dağıtan kolonlar ile kolonlardaki suyu kullanma yerlerine götüren bağlantı boruları da iç temiz su tesisatı olarak adlandırılır."
                image={Su}
              />
              <CompData
                // url="/home"
                heading="Sanayi Tesisatı"
                text="Kullanımının kolay olması, geriye herhangi bir tortu veya kül bırakmaması, çevreci ve ucuz olmasından dolayı yoğun bir şekilde tercih edilir.
                    Sanayi alanlarında doğalgaz kullanmak için buna uygun tesisat bulunması gerekir. Kaynaktan gelen gazın, kullanım alanlarına götürülmesi için izlenecek yol, teknik şartnameler ve ilgili yönetmeliklere uygun olmalıdır. Ayrıca bu iş için gerekli olan mekanik tesisatın, ortam şartlarına uygun olması ve diğer sistemleri etkilememesi gerekir."
                image={Sanayi}
              />
              <CompData
                // url="/home"
                heading="Kazan ve Boyler Tesisatı"
                text="Boyler, kullanma sıcak suyu hazırlamaya yarayan cihazdır. Günümüzde binalarda kullanım için gerekli sıcak su çeşitli yöntemlerle elde edilir. Genellikle küçük ve müstakil binalarda kombi, şofben ve termosifondan faydalanılır. İnsanların toplu yaşadığı ve faaliyet gösterdiği yerlerde bireysel sıcak su üretimi yerine daha çok ekonomik faydalar sağlayacak merkezi sıcak su hazırlama sistemleridir."
                image={Kazan}
              />
            </div>

            <div className="compcard">
              <CompData
                // url="/home"
                heading="Petek Temizleme"
                text="Petek temizliği; bu alanda uzmanlaşmış firmalar tarafından yapılan ısıtma sistemine ait bütün boruları ve petekleri kapsayan bir çalışmadır. Petek temizliği bir takım özel petek temizleme kimyasallar ve koruyucular kullanılarak itina ile yapılır."
                image={Petek}
              />
              <CompData
                // url="/home"
                heading="Su Kaçak Tespiti"
                text="Öncelikle manometre ile suyun basıncını kontrol etmektedirler. Manometre değerinde düşüş olursa, akustik dinleme cihazı ile su kaçağı dinlenmeye başlanır. Su kaçağı ufak bir çatlaktan sızıntı şeklindeyse ve sesi duyulmuyorsa, temiz su tesisatının basıncı yükseltilerek akustik dinleme cihazı ile tekrar dinlenir. Bu işlemin sonunda evdeki su kaçağı kesin olarak tespit edilir. Su kaçağının olduğu bölge hemen açılır. Onarımı yapılarak tekrar kapatılır. Onarım işlemi bittikten sonra su kaçağı olup olmadığı tekrar kontrol edilir."
                image={Kacak}
              />
              <CompData
                // url="/home"
                heading="Pimaş Açma"
                text=" Pompa veya tel kullanılarak tıkalı boruların açılmaya çalışılması en sık başvurulan yöntemlerden biridir. Eğer büyük bir sorun yoksa pompa veya tel gibi aparatlarla tıkanıklık giderilebilir. Kullanılan iki maddenin tepkimeye girmesi sonucu tıkanıklığa sebep olan maddeyi eriteceği ve böylece açılabileceği varsayılmaktadır."
                image={Pimas}
              />
            </div>
            <div className="compcard">
              <CompData
                // url="/home"
                heading="Lavabo Açma"
                text="Bu tip durumlarda yardımımıza yetişen pompa genelde geçici çözümler sağlamaktadır. Çünkü lavabodaki tıkanıklığı geçirebilse bile komple temizlenmesini sağlamaz.
                    Lavabo açıcılar, lavabo tıkanıklığı açma da akla gelen bir başka yöntemdir. Lavabo açmada son derece etkili olan lavabo açıcılar, solunması durumunda çeşitli rahatsızlıklara sebep olabilir. Biz bu tip durumların profesyonel ekiplere bırakılmasını tavsiye ediyoruz."
                image={Lavabo}
              />
              <CompData
                // url="/home"
                heading="Mutfak Banyo Tesisatı"
                text="Tesisata başlamadan önce yapılacak iş planlanır. Boruların geçeceği yerler tebeşirle işaretlenir. Kırmaya başlamadan önce zarar verilebilecek tesisat olup olmadığı kontrol edilir. Sonrasında belirlenen bölgedeki tesisat gerektiren işlemler yapılır. Devamında ise son kontroller yapılıp teslim edilir."
                image={Mutfak}
              />
              <CompData
                // url="/home"
                heading="Gömme Rezarvuar Montajı"
                text="Rezervuar tamiri sökme takma iç takım tamiri yeni rezervuar montajı için aradığınız tecrübeli usta bir telefonla kapınızda.Gömme rezervuar montajı ustalık gerektiren bir işlemdir. Yanlış yapılan montaj nedeni ile su kaçağı gibi sorunlar gözükebilir. Bu nedenle rezervuar montajı yapacak olan ustayı çok dikkatli seçmelisiniz."
                image={Gomme}
              />
            </div>
          </div>
        )
      ) : props.isEng ? (
        <div className="compcard">
          <CompData
            // IF NEEDED FOR PATH DİRECTİNG
            // url="/home"
            heading="%100 customer satisfaction"
            text="With a high customer satisfaction rate, we deliver all our projects to our customers as requested."
            image={Customer}
          />
          <CompData
            // url="/home"
            heading="Experienced Team"
            text="We fulfill our duties quickly and briefly with our strong, reliable and competent staff."
            image={Team}
          />
          <CompData
            // url="/home"
            heading="State-of-the-art Equipment"
            text="We protect occupational safety at the highest level with the tools and equipment we use."
            image={Tool}
          />
        </div>
      ) : (
        <div className="compcard">
          <CompData
            // IF NEEDED FOR PATH DİRECTİNG
            // url="/home"
            heading="%100 müşteri memnuniyeti"
            text="Yüksek müşteri memnuniyeti oranı ile tüm projelerimizi sağlam bir şekilde istenildiği gibi müşterilerimize teslim etmekteyiz."
            image={Customer}
          />
          <CompData
            // url="/home"
            heading="Deneyimli Ekip"
            text="Güvenilir ve işinin ehli güçlü kadromuzla görevimizi kısa ve hızlı şekilde yerine getiriyoruz."
            image={Team}
          />
          <CompData
            // url="/home"
            heading="Son teknoloji Ekipmanlar"
            text="Kullandığımız alet ve ekipmanlarla iş güvenliğini son düzeyde koruma altına alıyoruz."
            image={Tool}
          />
        </div>
      )}
    </div>
  );
}

export default Comp;
