import bg1 from "../assets/iklim.jpg";
import bg2 from "../assets/dogalgaz.jpg";
import bg3 from "../assets/gomme.jpg";
import bg4 from "../assets/mutfak.jpg";
import HistoryData from "./HistoryData";
import "./HistoryStyles.css";

const History = (props) => {
  return (
    <div className="destination">
      {props.isEng ? (
        <>
          <h1>History</h1>
          <p>Sadık Bekci</p>
          <HistoryData
            className="first-des"
            heading="Startup"
            text="Since 2012, industrial facilities, power plants, factories, residences, villas, dormitories, shopping malls,
            Buildings such as hospitals, hotels and stores within the framework of national and international standards,
             We are a company that provides services in the design and implementation of mechanical installations. Latest quality service, high customer satisfaction
             and with high motivation
             We serve you."
            img1={bg1}
            img2={bg2}
          />

          <HistoryData
            className="first-des-reverse"
            heading="Today"
            text="We have been providing professional services in the construction sector and installation works in Istanbul for more than 20 years. The main purpose of our trade
            And our goal is absolutely customer satisfaction. Natural gas installation, fire installation, industrial installation, water installation, leak detection professional
            are our areas of expertise. We provide the highest service to our customers, as in the past, with our new generation equipment and experienced employees."
            img1={bg3}
            img2={bg4}
          />
        </>
      ) : (
        <>
          <h1>Tarihçe</h1>
          <p>Sadık Bekci</p>
          <HistoryData
            className="first-des"
            heading="Başlangıç"
            text="2012 yılından beri endüstriyel tesis, enerji santrali, fabrika, konut, villa, yurt, alışveriş merkezi,
     hastane, otel, mağaza gibi yapıların ulusal ve uluslararası standartlar çerçevesinde,
      mekanik tesisatlarının projelendirme ve uygulamaları konularında hizmet veren bir firmayız. Son kalite hizmet , yüksek müşteri memnuniyeti
      ve yüksek motivasyon ile 
      sizlere hizmet ediyoruz."
            img1={bg1}
            img2={bg2}
          />

          <HistoryData
            className="first-des-reverse"
            heading="Günümüz"
            text="20 yılı aşkın bir süredir istanbul'da inşaat sektörü ve tesisat işlerinde profesyonel hizmet vermekteyiz. Ticaretimizin ana amacı
    ve hedefimiz kesinlikle müşteri memnuniyetidir. Doğalgaz tesisatı, yangın tesisatı, sanayi tesisatı, su tesisatı, kaçat tespiti profesyonel
    uzmanlık alanlarımızdır. Yeni nesil ekipmanlar ve deneyimli çalışanlarımız ile eskiden olduğu gibi şimdide müşterilerimize en üstün hizmeti vermekteyiz"
            img1={bg3}
            img2={bg4}
          />
        </>
      )}
    </div>
  );
};

export default History;
