import "./HeroStyles.css";
import { useState, useEffect } from "react";
import Slideshow from "./Slider";

function Hero(props) {
  //dark & light mode integration
  const [mode, setMode] = useState();

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme);
        setMode(colorScheme);
      });
  }, []);
  return (
    <>
      <div className={props.cName}>
        {props.showSlider === "true" ? (
          <Slideshow />
        ) : (
          <img src={props.heroImg} alt="HeroImg" />
        )}

        <div className={mode === "dark" ? "hero-text" : "hero-text-light"}>
          <h1
            className={mode === "dark" ? "hero-text-h1" : "hero-text-light-h1"}
          >
            {props.title}
          </h1>
          <p className={mode === "dark" ? "hero-text-p" : "hero-text-light-p"}>
            {props.text}
          </p>
          <a href={props.url} className={props.btnClass}>
            {props.buttonText}
          </a>
        </div>
      </div>
    </>
  );
}

export default Hero;
