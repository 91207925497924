import "./styles.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Service from "./routes/Service";
import Contact from "./routes/Contact";
import NotFound from "./routes/NotFound";
import Ataşehir from "./components/Pages/Ataşehir";
import Çekmeköy from "./components/Pages/Çekmeköy";
import Bostancı from "./components/Pages/Bostancı";
import Kadıkoy from "./components/Pages/Kadıköy";
import Acarkent from "./components/Pages/Acarkent";
import Fikirtepe from "./components/Pages/Fikirtepe";
import Kalamis from "./components/Pages/Kalamış";
import Yenisahra from "./components/Pages/Yenisahra";
import Camlica from "./components/Pages/Çamlıca";
import Sultanbeyligi from "./components/Pages/Sultanbeyliği";
import Beykoz from "./components/Pages/Beykoz";
import Osmanaga from "./components/Pages/Osmanağa";
import Yakacik from "./components/Pages/Yakacık";
import Moda from "./components/Pages/Moda";
import Aydos from "./components/Pages/Aydos";
import Dragos from "./components/Pages/Dragos";
import Soyak from "./components/Pages/Soyak";
import Kosuyolu from "./components/Pages/Koşuyolu";
import Goztepe from "./components/Pages/Göztepe";
import Kuzguncuk from "./components/Pages/Kuzguncuk";
import Beylerbeyi from "./components/Pages/Beylerbeyi";
import Altunizade from "./components/Pages/Altunizade";
import Tuzla from "./components/Pages/Tuzla";
import Umraniye from "./components/Pages/Umraniye";
import Uskudar from "./components/Pages/Uskudar";
import Sancaktepe from "./components/Pages/Sancaktepe";
import Kavacik from "./components/Pages/Kavacık";
import Acibadem from "./components/Pages/Acıbadem";
import Caddebostan from "./components/Pages/Caddebostan";
import Feneryolu from "./components/Pages/Feneryolu";
import Senesenevler from "./components/Pages/Senesenevler";
import Kozyatagi from "./components/Pages/Kozyatagi";
import Changelog from "./components/Extras/Changelog";
import Enghome from "./components/eng/Enghome";
import Engabout from "./components/eng/engAbout";
import Engservice from "./components/eng/engService";
import Engcontact from "./components/eng/engContact";
import Engchangelog from "./components/eng/Extras/engChangelog";
import Maltepe from "./components/Pages/Maltepe";
import Kartal from "./components/Pages/Kartal";
import Tasdelen from "./components/Pages/Tasdelen";
import Pendik from "./components/Pages/Pendik";
import Imes from "./components/Pages/Imes";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/eng/home" element={<Enghome />} />
        <Route path="/about" element={<About />} />
        <Route path="/eng/about" element={<Engabout />} />
        <Route path="/service" element={<Service />} />
        <Route path="/eng/service" element={<Engservice />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/eng/contact" element={<Engcontact />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/pages/atasehir" element={<Ataşehir />} />
        <Route path="/pages/maltepe" element={<Maltepe />} />
        <Route path="/pages/cekmekoy" element={<Çekmeköy />} />
        <Route path="/pages/kadikoy" element={<Kadıkoy />} />
        <Route path="/pages/suadiye" element={<Bostancı />} />
        <Route path="/pages/acarkent" element={<Acarkent />} />
        <Route path="/pages/fikirtepe" element={<Fikirtepe />} />
        <Route path="/pages/kalamis" element={<Kalamis />} />
        <Route path="/pages/yenisahra" element={<Yenisahra />} />
        <Route path="/pages/camlica" element={<Camlica />} />
        <Route path="/pages/sultanbeyligi" element={<Sultanbeyligi />} />
        <Route path="/pages/beykoz" element={<Beykoz />} />
        <Route path="/pages/osmanaga" element={<Osmanaga />} />
        <Route path="/pages/yakacik" element={<Yakacik />} />
        <Route path="/pages/aydos" element={<Aydos />} />
        <Route path="/pages/dragos" element={<Dragos />} />
        <Route path="/pages/moda" element={<Moda />} />
        <Route path="/pages/goztepe" element={<Goztepe />} />
        <Route path="/pages/soyak" element={<Soyak />} />
        <Route path="/pages/kosuyolu" element={<Kosuyolu />} />
        <Route path="/pages/kuzguncuk" element={<Kuzguncuk />} />
        <Route path="/pages/beylerbeyi" element={<Beylerbeyi />} />
        <Route path="/pages/altunizade" element={<Altunizade />} />
        <Route path="/pages/tuzla" element={<Tuzla />} />
        <Route path="/pages/umraniye" element={<Umraniye />} />
        <Route path="/pages/uskudar" element={<Uskudar />} />
        <Route path="/pages/sancaktepe" element={<Sancaktepe />} />
        <Route path="/pages/kavacik" element={<Kavacik />} />
        <Route path="/pages/acibadem" element={<Acibadem />} />
        <Route path="/pages/caddebostan" element={<Caddebostan />} />
        <Route path="/pages/feneryolu" element={<Feneryolu />} />
        <Route path="/pages/senesenevler" element={<Senesenevler />} />
        <Route path="/pages/kozyatagi" element={<Kozyatagi />} />
        <Route path="/pages/kartal" element={<Kartal />} />
        <Route path="/pages/tasdelen" element={<Tasdelen />} />
        <Route path="/pages/pendik" element={<Pendik />} />
        <Route path="/pages/imes" element={<Imes />} />
        <Route path="/extras/changelog" element={<Changelog />} />
        <Route path="/eng/extras/changelog" element={<Engchangelog />} />
      </Routes>
    </div>
  );
}
