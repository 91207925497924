export const MenuItems = [
  {
    title: "Ana Sayfa",
    Eng: "Home",
    url: "/home",
    engUrl: "/eng/home",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
  },
  {
    title: "Hakkımızda",
    Eng: "About",
    url: "/about",
    engUrl: "/eng/about",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info",
  },
  {
    title: "Hizmet",
    Eng: "Services",
    url: "/service",
    engUrl: "/eng/service",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase",
  },
  {
    title: "İletişim",
    Eng: "Contact",
    url: "/contact",
    engUrl: "/eng/contact",
    cName: "nav-links",
    icon: "fa-solid fa-address-book",
  },
];
