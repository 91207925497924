import Footer from "../Footer";
import Hero from "../Hero";
import Navbar from "../Navbar";
import History from "../History";
import { useState, useEffect } from "react";
import Background from "../../assets/background.jpg";
import Comp from "../Comp";
import "../h_button.css";

function Enghome() {
  //dark & light mode integration
  const [mode, setMode] = useState();

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme);
        setMode(colorScheme);
      });
  }, []);

  return (
    <>
      <Navbar isEng="true" />
      <Hero
        cName={mode === "dark" ? "hero" : "hero-light"}
        heroImg={Background}
        title="Installation Mechanic Service"
        text="
        The main purpose and goal of our business is strictly customer satisfaction and to provide quality and fast technical support."
        url="/eng/contact"
        btnClass={mode === "dark" ? "show" : "show-light"}
        buttonText="Contact Us"
        showSlider="true"
      />
      <History isEng="true" />
      <h1>Customer Satisfaction</h1>
      <p>Technical Support</p>
      <Comp isdetailed="false" isEng="true" />
      <div className="comp-button-div">
        <a href="/eng/service">
          <button className="comp-button">More ⇨</button>
        </a>
      </div>
      <Footer isEng="true" />
    </>
  );
}

export default Enghome;
