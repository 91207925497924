import "./ContactFormStyles.css";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";

function ContactForm(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const userCollectionRef = collection(db, "messages");

  const handleSubmit = (e) => {
    e.preventDefault();

    addDoc(userCollectionRef, {
      name: name,
      email: email,
      message: message,
      subject: subject,
    })
      .then(() => {
        alert("Mesajınız gönderildi👍");
      })
      .catch((error) => {
        alert(error.message);
      });

    setName("");
    setEmail("");
    setMessage("");
    setSubject("");
  };

  return (
    <div className="form-container">
      {props.isEng ? (
        <>
          <h1>Message</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Your name"
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Subject"
            />
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message"
              rows={4}
            ></textarea>
            <button type="submit">Send</button>
          </form>
        </>
      ) : (
        <>
          <h1>İleti gönderin</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Adınız"
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Konu"
            />
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Mesaj"
              rows={4}
            ></textarea>
            <button type="submit">gönder</button>
          </form>
        </>
      )}
    </div>
  );
}

export default ContactForm;
