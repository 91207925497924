import "./AboutUsStyles.css";
import ServiceArea from "./serviceArea";

function AboutUs(props) {
  return (
    <div className="about-container">
      {props.isEng ? (
        <>
          <h1>Our History</h1>
          <p>
            Construction industry and plumbing in Istanbul for more than 20
            years We provide professional service in your work. The main purpose
            of our trade and Our goal is absolutely customer satisfaction.
            natural gas installation, fire installation, industrial
            installation, water installation, leak detection are our
            professional areas of expertise. New generation equipment and With
            our experienced employees, now as before We provide the highest
            service to our customers
          </p>

          <h1>Our Mission</h1>
          <p>
            To draw new horizons by taking a leading role in the plumbing
            industry. This Our goals to achieve our mission: Profitability,
            efficiency Effective and efficient resource management within the
            framework of efficiency principles To continue continuous
            improvement activities for Marketing strategy and policies
            compatible with production infrastructure to identify and implement.
          </p>

          <h1>Our Vision</h1>
          <p>
            In the sector, powered by the quality of the services we offer to
            our country To be preferred at the top of their choices. Service
            quality and To define it as a target to increase its capacity day by
            day.
          </p>

          <h1>Service Areas</h1>

          <ServiceArea />
        </>
      ) : (
        <>
          <h1>Tarihimiz</h1>
          <p>
            20 yılı aşkın bir süredir istanbul'da inşaat sektörü ve tesisat
            işlerinde profesyonel hizmet vermekteyiz. Ticaretimizin ana amacı ve
            hedefimiz kesinlikle müşteri memnuniyetidir. Doğalgaz tesisatı,
            yangın tesisatı, sanayi tesisatı, su tesisatı, kaçat tespiti
            profesyonel uzmanlık alanlarımızdır. Yeni nesil ekipmanlar ve
            deneyimli çalışanlarımız ile eskiden olduğu gibi şimdide
            müşterilerimize en üstün hizmeti vermekteyiz
          </p>

          <h1>Misyonumuz</h1>
          <p>
            Tesisat sektöründe öncü rolü üstlenerek yeni ufuklar çizmektir. Bu
            misyonu gerçekleştirebilmek için hedeflerimiz: Karlılık, verimlilik
            ve etkinlik esasları çerçevesinde etkin ve verimli kaynak yönetimi
            için sürekli iyileştirme faaliyetlerini sürdürmek, ülke içinde
            üretim alt yapısıyla uyumlu pazarlama stratejisi ve politikaları
            tespit etmek ve uygulamaktır.
          </p>

          <h1>Vizyonumuz</h1>
          <p>
            Ülkemize sunduğumuz hizmetlerin kalitesinden güç alarak sektöründe
            tercihlerinin başında tercih edilen olabilmek. Hizmet kalite ve
            kapasitesini her geçen gün arttırmayı hedef olarak nitelendirmek.
          </p>

          <h1>Hizmet Bölgelerimiz</h1>

          <ServiceArea />
        </>
      )}
    </div>
  );
}

export default AboutUs;
