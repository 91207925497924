import Hero from "../Hero";
import Navbar from "../Navbar";
import AboutImg from "../../assets/service1.jpg";
import Footer from "../Footer";
import Comp from "../Comp";

function Engservice() {
  return (
    <>
      <Navbar isEng="true" />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Services"
        text="100% quality service"
        btnClass="hide"
        showSlider="false"
      />
      <Comp isDetailed="true" isEng="true" />
      <Footer isEng="true" />
    </>
  );
}

export default Engservice;
