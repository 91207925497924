import Navbar from "../../Navbar";
import Hero from "../../Hero";
import "../../Extras/ChangeLog.css";
import background from "../../../assets/black-bg.jpg";

function Engchangelog() {
  return (
    <div>
      <Navbar isEng="true" />
      <Hero
        heroImg={background}
        title="Changelog"
        cName="hero-mid"
        btnClass="hide"
        showSlider="false"
      />
      <div>V1 -&gt; Deployed(09.29.23) </div>
      <div>V1.1 -&gt; DNS problems fixed(10.29.23) </div>
      <div>
        V1.2 -&gt; New service areas added and some bugs fixed(12.19.23)
      </div>
      <a className="log-button" href="/eng/home">
        Go Back
      </a>
    </div>
  );
}

export default Engchangelog;
