import "./Comp.css";

function CompData(props) {
  return (
    <a href={props.url} className="comp-card">
      <div className="comp-image">
        <img src={props.image} alt="img" />
      </div>
      <h4>{props.heading}</h4>
      <p>{props.text} </p>
    </a>
  );
}

export default CompData;
