import React from "react";
import "../components/NotFound.css";

function NotFound() {
  return (
    <div className="container">
      <div className="content">
        <h2>404</h2>
        <h4>Opps! Sayfa bulunamadı!</h4>
        <p>
          {/* The page you were looking for doesn't exist. You may have mistyped the
          adress or the page may have moved. */}
          Şu anda aradığınız sayfa bulunamamaktadır. Yanlış yazmış olabilirsiniz
          veya sayfa taşınmış olabilir.
        </p>
        <a href="/">Anasayfa</a>
      </div>
    </div>
  );
}

export default NotFound;
